<template>
    <!-- 仓库资料 -->
    <div class="warehouseData">
        <div class="table">
            <div class="table-operation">
                <el-button type="warning" icon="el-icon-plus"
                    @click="dialogVisible = true, dialogType = 1, form = {}">新增</el-button>
                <el-button type="warning" icon="el-icon-plus" @click="plDialogVisible = true, form = {}">批量新增</el-button>
                <el-button type="info" icon="el-icon-refresh-left" @click="loading = true, getList()"></el-button>
            </div>
            <Table :data="tableData" :total="total" v-loading="loading"
                @size-change="pageNum = $event, loading = true, getList()"
                @current-change="page = $event, loading = true, getList()">
                <el-table-column label="休息日" prop="date" align="center"></el-table-column>
                <el-table-column label="星期" prop="week" align="center"></el-table-column>
                <el-table-column label="操作" align="center" width="160">
                    <template slot-scope="scope">
                        <el-button type="warning" size="mini"
                            @click="dialogVisible = true, dialogType = 2, openDialog(scope.row)">编辑</el-button>
                        <el-button type="danger" size="mini" @click="removeFn(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </Table>
        </div>
        <!-- 新增 -->
        <el-dialog :title="dialogType == 1 ? '新增' : '编辑'" :visible.sync="dialogVisible" :close-on-click-modal="false"
            v-el-drag-dialog width="30%">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="休息日">
                    <el-date-picker v-model="form.date" type="date" placeholder="选择休息日" value-format="yyyy-MM-dd"
                        style="width: 100%;">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="warning" @click="confirmFn">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 批量新增 -->
        <el-dialog title="批量新增" :visible.sync="plDialogVisible" :close-on-click-modal="false" v-el-drag-dialog width="30%">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="休息日">
                    <el-date-picker v-model="form.date" type="daterange" range-separator="至" start-placeholder="开始日期"
                        end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 100%;">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="星期">
                    <el-select v-model="form.week" multiple placeholder="请选择" style="width:100%">
                        <el-option v-for="item in weekList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="warning" @click="plConfirmFn">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
    
<script>
export default {
    data() {
        return {
            tableData: [],
            loading: true,
            page: 1,
            pageNum: 10,
            total: 0,
            // 新增、编辑弹窗
            dialogType: 1,//1新增2编辑
            dialogVisible: false,
            plDialogVisible: false,
            form: {},
            weekList: [
                {
                    id: 1,
                    name: '星期一'
                },
                {
                    id: 2,
                    name: '星期二'
                },
                {
                    id: 3,
                    name: '星期三'
                },
                {
                    id: 4,
                    name: '星期四'
                },
                {
                    id: 5,
                    name: '星期五'
                },
                {
                    id: 6,
                    name: '星期六'
                },
                {
                    id: 7,
                    name: '星期日'
                }
            ]
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        getList() {
            this.$http.post(`/erp/v1/delivery_rest/lst`, {
                page: this.page,
                pageNum: this.pageNum,
            }).then((res) => {
                const { code, data, msg } = res.data
                this.loading = false
                if (code == 200) {
                    this.tableData = data.rows
                    this.total = data.total
                } else {
                    this.$message.error(msg);
                }
            });
        },
        // 编辑弹窗
        openDialog(item) {
            this.form = { ...item }
            this.form.id = item.id
        },
        // 确定
        confirmFn() {
            if (!this.form.date) {
                this.$message.error('请选择休息日日期');
                return
            }
            let url = this.dialogType == 1 ? '/erp/v1/delivery_rest/add' : '/erp/v1/delivery_rest/modify'
            this.$http.post(`${url}`, this.form).then((res) => {
                const { code, msg } = res.data
                if (code == 200) {
                    this.$message.success(msg);
                    this.dialogVisible = false
                    this.getList()
                } else {
                    this.$message.error(msg);
                }
            });
        },
        // 批量确定
        plConfirmFn() {
            if (!this.form.date) {
                this.$message.error('请选择休息日日期');
                return
            }
            let info = {
                begin: this.form.date[0],
                end: this.form.date[1],
                week: this.form.week.join(',')
            }
            this.$http.post('/erp/v1/delivery_rest/batch', info).then((res) => {
                const { code, msg } = res.data
                if (code == 200) {
                    this.$message.success(msg);
                    this.plDialogVisible = false
                    this.getList()
                } else {
                    this.$message.error(msg);
                }
            });
        },
        // 删除
        removeFn(item) {
            this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(`/erp/v1/delivery_rest/delete`, {
                    id: item.id
                }).then((res) => {
                    const { code, msg } = res.data
                    if (code == 200) {
                        this.$message.success(msg);
                        this.getList()
                    } else {
                        this.$message.error(msg);
                    }
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        }
    },
}
</script>
    
<style lang="scss" scoped></style>